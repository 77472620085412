import {
   BrowserRouter, Route, Routes,
} from "react-router-dom"
import Demo from "../demo/demo";
import Team from '../team/team'
import Home from "../home/home";
import NotFound from "../notfound/notfound";
import SignUp from '../SignUp/SignUp'
import Login from "../Login/Login";
import Navbar from "../navbar/navbar";

const Router = () => (
   <BrowserRouter>
      <Navbar />
      <Routes>
         <Route path='/' element={<Home></Home>} />
         <Route path='/demo' element={<Demo></Demo>} />
         <Route path='/team' element={<Team></Team>} />
         <Route path='/login' element={<Login></Login>} />
         <Route path='/sign-up' element={<SignUp></SignUp>} />
         <Route path='/*' element={<NotFound></NotFound>} />
      </Routes>
   </BrowserRouter>
);


Router.propTypes = {};

// router.defaultProps = {};

export default Router;
