import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DemoWrapper } from './demo.styled';

import { useNavigate } from 'react-router-dom';
// let backendURL = process.env.REACT_APP_DEV_ENV == "TRUE" ? "http://127.0.0.1:8000/api" : 'https://simflection.tech/api'
let backendURL = 'https://simflection.tech/api'

const Demo = () => {
   const navigate = useNavigate()
   useEffect(() => {
      //try and authenticate if good, navigate to demo
      const options = {
         method: "GET",
         headers: {},
         credentials: 'include',
      }
      fetch(backendURL + '/authenticate_user', options).then(async (response) => {
         if (!response.ok) {
            navigate('/login')
         }
      })
   }, [])
   return (
      <DemoWrapper>
         Demo Component
      </DemoWrapper>
   );
}

Demo.propTypes = {};

// Demo.defaultProps = {};

export default Demo;
