import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SignUpWrapper } from './SignUp.styled';

// let signupUrl = process.env.REACT_APP_DEV_ENV == "TRUE" ? 'http://127.0.0.1:8000/user_sign_up' : 'http://simflection-site-backend-server.eba-4cqpppba.us-east-2.elasticbeanstalk.com/user_sign_up'
let signupUrl = 'https://simflection.tech/api/user_sign_up'

const SignUp = (props) => {
   function submitForm(e) {
      'use server';
      const form = e.target;
      const data = new FormData(form)
      e.preventDefault()

      try {
         fetch(signupUrl, { method: "POST", body: data })
      }
      catch (error) {
         //TODO: make toaster maybe? like the pop up thing in the corner
         console.log(error);
      }
   };


   return (
      <SignUpWrapper>
         <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-24 lg:px-8">

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
               <img
                  className="mx-auto h-10 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt="Your Company"
               />
               <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign up for email notifications
               </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
               <form className="space-y-6" onSubmit={submitForm}>
                  <div>
                     <div className="flex items-center justify-between">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                           First Name
                        </label>
                     </div>
                     <div className="mt-2">
                        <input
                           id="first-name"
                           name="first-name"
                           type="first-name"
                           autoComplete="given-name"
                           required
                           className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                     </div>
                  </div>

                  <div>
                     <div className="flex items-center justify-between">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                           Last Name
                        </label>
                     </div>
                     <div className="mt-2">
                        <input
                           id="last-name"
                           name="last-name"
                           type="last-name"
                           autoComplete="family-name"
                           required
                           className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                     </div>
                  </div>

                  <div>
                     <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                     </label>
                     <div className="mt-2">
                        <input
                           id="email"
                           name="email"
                           type="email"
                           autoComplete="email"
                           required
                           className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                     </div>
                  </div>


                  <div>
                     <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Sign up
                     </button>
                  </div>
               </form>

               {/* <p className="mt-10 text-center text-sm text-gray-500">
               Not a member?{' '}
               <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
               Start a 14 day free trial
               </a>
            </p> */}
            </div>
         </div>
      </SignUpWrapper >
   )
};

SignUp.propTypes = {};

// SignUp.defaultProps = {};

export default SignUp;
