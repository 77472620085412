import PropTypes from 'prop-types';
import { LoginWrapper } from './Login.styled';
import logo from '../../assets/simflectionLogo.png'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// let backendURL = process.env.REACT_APP_DEV_ENV == "TRUE" ? "http://127.0.0.1:8000/api" : 'https://simflection.tech/api'
let backendURL = 'https://simflection.tech/api'

const Login = () => {

   const navigate = useNavigate()
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')

   useEffect(() => {
      //try and authenticate if good, navigate to demo
      const options = {
         method: "GET",
         headers: {},
         credentials: 'include',
      }
      fetch(backendURL + '/authenticate_user', options).then(async (response) => {
         if (response.ok) {
            navigate('/demo')
         }
      })
   }, [])

   const login = (e) => {
      e.preventDefault()
      const options = {
         method: 'POST',
         headers: {},
         body: JSON.stringify({
            'email': email,
            'password': password
         }),
         credentials: 'include'
      }
      // console.log(backendURL + 'api/login_account')
      fetch(backendURL + '/login_account', options).then(async (response) => {
         if (response.ok) {
            //jwt token automatically set
            //redirect
            navigate("/demo")
         }
         else { // should render extra component
            console.error('The credentials you entered are not correct. Please try again.')
         }
      })
      setPassword('')
   }

   return (
      <LoginWrapper>
         <div class="flex min-h-full flex-col justify-center px-6 py-24 lg:px-8">
            <div class="sm:mx-auto sm:w-full sm:max-w-sm">
               {/* <img class="mx-auto h-10 w-auto" src={logo} alt="Your Company" /> */}
               <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
            </div>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
               <form class="space-y-6" onSubmit={login} method="POST">
                  <div>
                     <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                     <div class="mt-2">
                        <input id="email" name="email" type="email" autocomplete="email" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={(e) => setEmail(e.target.value)} value={email} />
                     </div>
                  </div>

                  <div>
                     <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                        {/* <div class="text-sm">
                           <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
                        </div> */}
                     </div>
                     <div class="mt-2">
                        <input id="password" name="password" type="password" value={password} autocomplete="current-password" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={(e) => { setPassword(e.target.value) }} />
                     </div>
                  </div>

                  <div>
                     <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign in</button>
                  </div>
               </form>

               {/* <p class="mt-10 text-center text-sm text-gray-500">
                  Not a member?
                  <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Start a 14 day free trial</a>
               </p> */}
            </div>
         </div>
      </LoginWrapper>
   );
}

Login.propTypes = {};

// Login.defaultProps = {};

export default Login;
