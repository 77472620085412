import React from 'react';
import PropTypes from 'prop-types';
import { teamWrapper } from './team.styled';
import darren from '../../assets/darrenHeadshot.jpg'
import tiffany from '../../assets/tiffany.jpg'
import faith from '../../assets/faith.jpg'
import yan from '../../assets/yan.jpg'

const people = [
   {
      name: 'Faith Lum',
      role: 'Co-Founder',
      imageUrl: faith,
      description: 'Faith is focused on building systems that help people satisfy their curiosity about themselves and the world. Her experience in software engineering, infrastructure, machine learning, research, and data analysis drives our mission to deliver insightful and user-centric solutions.'
   },
   {
      name: "Tiffany Ngai",
      role: "Co-Founder",
      imageUrl: tiffany,
      description: 'Tiffany brings extensive experience in AI, machine learning, signal processing, and data analysis. With a strong background in both industry and academia, she has served as the main developer on several projects and led research initiatives.'
   },
   {
      name: "Darren Choi",
      role: "Lead Developer",
      imageUrl: darren,
      description: "Darren creates scalable, user-friendly applications, with a particular expertise in integrating cutting-edge technologies like augmented reality."
   },
   {
      name: "Yan Zhang",
      role: "Lead Product Designer",
      imageUrl: yan,
      description: "Yan advocates for user-centric design and always believes there's a better way to do something. She blends creativity, technology, and aesthetics to craft seamless user experiences to foster more confident and satisfactory purchases for users in their shopping journey."
   }
   // More people...
]

const Team = () => (
   <teamWrapper>
      <div className="bg-white py-24 sm:py-32">
         <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
            <div className="max-w-2xl">
               <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
               <p className="mt-6 text-lg leading-8 text-gray-600">
                  At Simflection, a dynamic team of innovators and fashion enthusiasts drives our mission to revolutionize shopping. Led by co-founders Tiffany Ngai and Faith Lum,  we blend cutting-edge technology with style. Together, we're creating a seamless and engaging shopping experience for the future.
               </p>
            </div>
            <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
               {people.map((person) => (
                  <li key={person.name}>
                     <div className="flex items-center gap-x-6">
                        <img className="h-16 w-16 rounded-full" src={person.imageUrl} alt="" />
                        <div>
                           <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                           <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                           <p className='text-sm'>{person.description}</p>
                        </div>
                     </div>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   </teamWrapper>
);

Team.propTypes = {};

Team.defaultProps = {};

export default Team;
