import React from 'react';
// import PropTypes from 'prop-types';
import { HomeComponent } from './home.styled';

const Home = () => (
   <HomeComponent>
      <div className='py-4'></div>
      <div className='container mx-auto px-4 py-6 max-w-7xl'>
         <div className='container mx-auto px-4 flex justify-center'>
            <div className='font-bold text-4xl py-8'>
               YOUR BODY, YOUR PREFERENCE, YOUR OUTFITS
            </div>
         </div>
         <div className='section-header py-2'>
            <h1 className='font-bold text-4xl underline'>Mission</h1>
         </div>
         <p className='paragraph text-gray-600'>
            We empower retailers to find the best clothing selections for customers. By recommending apparel that fits customers' body type, skin tone, and preference, and by offering a realistic and immersive online shopping experience, we enhance customer satisfaction and reduce costs for retailers.
         </p>
      </div>



      <div className='container mx-auto px-4 py-6 max-w-7xl'>
         <div className='section-header'>
            <p className='text-4xl underline py-2 font-bold'>Problem</p>
         </div>
         <b>Unrealistic clothing experience</b>
         <ul>
            <li>
               <div className='paragraph text-gray-600 py-2'>
                  Customers often struggle to determine how clothing will fit and feel when shopping online. The inability to try on items or feel the fabric makes the entire shopping experience less engaging and satisfying.
               </div>
            </li>
         </ul>
         <b>High return rates, increased cost of operation</b>
         <ul>
            <li>
               <div className='paragraph text-gray-600 py-2'>
                  Online clothing purchases have a return rate of 40%, with 70% of these returns attributed to sizing issues. This significant return rate costs U.S retailers over $500 billion annually.
               </div>
            </li>
         </ul>
      </div>
      <div className='container mx-auto px-4 py-6 max-w-7xl'>
         <div className='section-header py-2'>
            <h1 className='font-bold text-4xl underline'>Solution</h1>
         </div>
         <div className=' text-gray-600 py-2'>
            <b>Sizing recommender (coming soon)</b>
            <li>By recommending the right sizing for customers, we ensure they can confidently select the correct size, enhancing their shopping experience and reducing the likelihood of returns.</li>
         </div>
         <div className='text-gray-600 py-2'>
            <b>Styling recommender (coming soon)</b>
            <li>
               Based on customers' body type, skin tone, personal preferences, existing wardrobe, and current trends, we recommend the best clothing options to meet their needs and enhance their style.
            </li>
         </div>
         <div className='text-gray-600 py-2'>
            <b>3-D Fitting (coming soon)</b>
            <li>
               Customers can use their avatars to quickly understand how the clothing will look and feel on them, providing a more realistic online shopping experinece.
            </li>
         </div>
      </div>

   </HomeComponent >
);

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
